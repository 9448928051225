// your js

// lazyload
(function(w, d){
	var b = d.getElementsByTagName('body')[0];
	var s = d.createElement("script"); 
	var v = !("IntersectionObserver" in w) ? "8.16.0" : "10.19.0";
	s.src = "https://cdn.jsdelivr.net/npm/vanilla-lazyload@" + v + "/dist/lazyload.min.js";
	b.appendChild(s); 
	s.onload = function(){
			w.lazy = new LazyLoad({
					elements_selector: ".lazy"
			});
	}
}(window, document)); 


document.addEventListener('DOMContentLoaded', function () {
	// svg inliner 
	if(document.querySelector('.svg')){
		new SVGInliner(document.querySelectorAll('.svg'));
	}
	window.addEventListener('scroll', menuScrolled);
})


// burger opened
document.querySelector('.js-burger__open').addEventListener('click', openMenu)
function openMenu(){
	this.classList.toggle('is-opened');
	document.querySelector('.header').classList.toggle('is-opened');
	document.querySelector('.header .navigation').classList.toggle('is-opened');
}


// calc hero section padding
if(document.querySelector('.hero')){
	window.addEventListener('resize', headerPaddingCalc)
	
	headerPaddingCalc();
	function headerPaddingCalc(){
		$('.js-topPadding').css('padding-top', document.querySelector('.header').clientHeight + 'px');
	}
}

// change color for widgets
if(document.querySelector('.hero') && document.querySelector('.widget')){
	const hero = document.querySelector('.hero'),
				widget = document.querySelector('.widget');


	window.addEventListener('scroll', changeWidgetColor);
	changeWidgetColor();
	
	function changeWidgetColor() {
		if(hero.getBoundingClientRect().bottom < widget.getBoundingClientRect().bottom){
			widget.classList.add('is-black');
		} else {
			widget.classList.remove('is-black');
		}
	}
}


// hero slider
if(document.querySelector('.js-hero__slider')) {
	$('.js-hero__slider').slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		// arrows: false,
		dots: false,
		prevArrow: '.hero__nav .arrow-prev',
		nextArrow: '.hero__nav .arrow-next'
	})
}

// main services slider
if(document.querySelector('.js-service__slider') && document.querySelectorAll('.js-service__slider .services__slide').length > 4) {
	$('.services__arrows').show();
	$('.js-service__slider').slick({
		slidesToShow: 4,
		slidesToScroll: 4,
		// arrows: false,
		dots: false,
		prevArrow: '.js-services__arrows .arrow-prev',
		nextArrow: '.js-services__arrows .arrow-next'
	})
}

// index recommend slider
if(document.querySelector('.js-recommends__slider')) {
	$('.js-recommends__slider').slick({
		slidesToShow: 3,
		slidesToScroll: 3,
		// arrows: false,
		dots: false,
		prevArrow: '.recommends__arrows .arrow-prev',
		nextArrow: '.recommends__arrows .arrow-next',
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
				}
			},
			{
				breakpoint: 639,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				}
			}
		]
	})
}

// index recommends letters slider
if(document.querySelector('.js-recommends-letters__slider')) {
	$('.js-recommends-letters__slider').slick({
		slidesToShow: 4,
		slidesToScroll: 4,
		// arrows: false,
		dots: false,
		prevArrow: '.recommends__arrows .arrow-prev',
		nextArrow: '.recommends__arrows .arrow-next',
		responsive: [
			{
				breakpoint: 1279,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
				}
			},
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
				}
			},
			{
				breakpoint: 639,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				}
			},
		]
	})

	if(document.querySelector('.js-popup__recommend')) {
		document.querySelectorAll('.js-recommends__popup').forEach( node => {
			node.addEventListener('click', openRecommendsPopup);

			function openRecommendsPopup(e) {
				const imgSrc = this.querySelector('img').src;
				document.querySelector('.js-popup__recommend img').setAttribute('src', imgSrc);
				document.querySelector('body').classList.add('body-overflow');
				$('.js-popup__recommend').fadeIn();
			}
		})
	}
}

// vacancy slider
if(document.querySelector('.js-positions__slider')) {
	$('.js-positions__slider').slick({
		slidesToShow: 4,
		slidesToScroll: 4,
		dots: false,
		infinite: false,
		prevArrow: '.js-positions__arrows .arrow-prev',
		nextArrow: '.js-positions__arrows .arrow-next',
		responsive: [
			{
				breakpoint: 1279,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
				}
			},
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
				}
			},
			{
				breakpoint: 479,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				}
			},
		]
	})

	filterVacancy();
	function filterVacancy() {
		const filterObj = {
			vacancy: '',
			city: '',
		};
	
		function filter(e) {
			const val = e.target.value;
			filterObj[e.target.name] = e.target.value;
			
			$('.js-positions__slider').slick('slickUnfilter');
			
			$('.js-positions__slider').slick('slickFilter', function (index, slide) {
				const [vacancy, city] = [slide.querySelector('.positions__slide').dataset.vacancy, slide.querySelector('.positions__slide').dataset.city];
				let regVacancy = new RegExp(filterObj.vacancy.trim(), 'i'),
						regCity = new RegExp(filterObj.city.trim(), 'i'),
						validate = true;
				
				if(filterObj.vacancy && filterObj.vacancy !== '') validate = regVacancy.test(vacancy);
				if(validate && filterObj.city && filterObj.city !== '') validate = regCity.test(city);
				
				return validate;
			});
		}
	
		document.querySelectorAll('.js-position__filter input').forEach( input => {
			input.addEventListener('keyup', filter);
		})	
	}
	
}

// clients slider
if(document.querySelector('.js-clients__slider')) {
	$('.js-clients__slider').slick({
		slidesToShow: 4,
		slidesToScroll: 4,
		// arrows: false,
		dots: false,
		prevArrow: '.js-clients__arrows .arrow-prev',
		nextArrow: '.js-clients__arrows .arrow-next',
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
				}
			},
			{
				breakpoint: 479,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				}
			},
		]
	})
}

// trust slider
if(document.querySelector('.js-trust__slider') && document.querySelector('.js-trust__navigation')) {
	document.querySelectorAll('.js-trust__navigation li').forEach(node => node.addEventListener('click', changeTab));

	// on init, set active slider and tab
	const actoveAttrOnInit = document.querySelector('.js-trust__navigation li.is-active').dataset.filter,
				activeSlider = document.querySelector(`.trust__block[data-filter="${actoveAttrOnInit}"]`);
	activeSlider.classList.add('is-active');
	toggleShowArrows(activeSlider.querySelectorAll('.trust__slide').length);

	$('.js-trust__slider').each( (index, slider) => {
		$(slider).slick({
			dots: false,
			arrows: false,
			rows: 2,
			slidesPerRow: 4,
			infinite: false,
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						rows: 2,
						slidesPerRow: 3,
					}
				},
				{
					breakpoint: 767,
					settings: {
						rows: 2,
						slidesPerRow: 2,
					}
				}
			]
		})
		
		sliderEventsOnArrows(actoveAttrOnInit);
	})

	function changeTab(e) {
		const filterAttr = this.dataset.filter,
					slider = document.querySelector(`.trust__block[data-filter="${filterAttr}"]`);
		document.querySelectorAll('.js-trust__navigation li').forEach( node => node.classList.remove('is-active'));
		document.querySelectorAll('.trust__block').forEach( node => node.classList.remove('is-active'));
		this.classList.add('is-active');
		slider.classList.add('is-active');
		$('.js-trust__slider').slick('slickGoTo', 0);

		sliderEventsOnArrows(filterAttr);
		const slides = slider.querySelectorAll('.trust__slide').length;
		toggleShowArrows(slides);
	}

	function sliderEventsOnArrows(attr) {
		document.querySelector('.trust__arrows .arrow-prev').removeEventListener('click', changeSlide);
		document.querySelector('.trust__arrows .arrow-next').removeEventListener('click', changeSlide);
		document.querySelector('.trust__arrows .arrow-prev').addEventListener('click', changeSlide.bind(null, attr, 'prev'));
		document.querySelector('.trust__arrows .arrow-next').addEventListener('click', changeSlide.bind(null, attr, 'next'));
	}

	function changeSlide(attr, route, e) {
		if(route === 'prev') $(`.js-trust__slider[data-slider-attr="${attr}"]`).slick('slickPrev');
		if(route === 'next') $(`.js-trust__slider[data-slider-attr="${attr}"]`).slick('slickNext');
	}

	function toggleShowArrows(slidesNumber) {
		const sizes = window.innerWidth > 1023 ? 8 : window.innerWidth < 1024 && window.innerWidth > 768 ? 6 : window.innerWidth < 767 ? 4 : 8;
		if(slidesNumber <= sizes) {
			document.querySelector('.trust__arrows').classList.add('is-hidden');
		} else {
			document.querySelector('.trust__arrows').classList.remove('is-hidden');
		}
	}
}


// contacts tabs
if(document.querySelector('.js-contacts__tabs') && document.querySelector('.js-contacts__blocks')) {
	tabsShowOnInit();
	document.querySelectorAll('.js-contacts__tabs li[data-filter]').forEach(navigationEvent);

	function navigationEvent(li){
		li.addEventListener('click', tabsShow);
	}

	function tabsShow() {
		const attrName = this.dataset.filter;
		document.querySelectorAll('.js-contacts__tabs li[data-filter]').forEach( li => li.classList.remove('is-active'))
		this.classList.add('is-active');
		toggleBlocks(attrName);
	}

	function tabsShowOnInit() {
		let attrName = document.querySelector('.js-contacts__tabs li.is-active').dataset.filter;
		toggleBlocks(attrName);
	}

	function toggleBlocks(attr) {
		document.querySelectorAll('.js-contacts__blocks .maps__tab[data-filter]').forEach( node => node.classList.remove('is-active'));
		document.querySelector(`.js-contacts__blocks .maps__tab[data-filter="${attr}"]`).classList.add('is-active');
	}
}


// close open popups
if(document.querySelector('.popup') && document.querySelector('.js-popup__close')) {
	function closePopups() {
		document.querySelectorAll('.js-popup__close').forEach(close => {
			close.addEventListener('click', popupClose);
		})

		function popupClose() {
			document.querySelector('body').classList.remove('body-overflow');
			document.querySelectorAll('.popup').forEach(popup => $(popup).fadeOut());
		}
	}
	closePopups();
}


// services steps navigation
if(document.querySelector('.js-steps__navigation')) {
	const stepsNodes = document.querySelectorAll('.js-steps__navigation li'),
				stepsSections = document.querySelectorAll('.info__container'),
				navigation = document.querySelector('.steps__navigation'),
				stepsContainer = document.querySelector('.steps__container');

	calcMarginForSteps();
	smoothScrollForLinks();
	window.addEventListener('scroll', stepsNavigationPainting);

	function calcMarginForSteps() {
		if(window.innerWidth < 1023) return;
		if(window.innerWidth > 1023) stepsContainer.style.marginTop = -navigation.getBoundingClientRect().height + 'px';
	}

	function smoothScrollForLinks() {
		const links = document.querySelectorAll('.js-steps__navigation a');
		links.forEach( link => {
			$(link).click( function(e){
				e.preventDefault();
				$('html, body').animate({
				scrollTop: $('#' + link.href.split('#')[1]).offset().top
				}, 1e3);
			})
		})
	}

	function stepsNavigationPainting() {
		if(window.innerWidth < 1023) return;
		let positions = {};
		calcBlocksPosition(positions);
		markActiveItem(positions);
	}

	function calcBlocksPosition(positions) {
		stepsSections.forEach( (node, index) => {
			const { top } = node.getBoundingClientRect();

			if(top < window.innerHeight / 1.5) positions[index] = true;
		})
	}

	function markActiveItem(positions) {
		stepsNodes.forEach( listItem => listItem.classList.remove('is-active'));
		for( let pos in positions) {
			stepsNodes[pos].classList.add('is-active');
		}
	}
}


function menuScrolled(e){
	let header = document.querySelector('.header'),
			headerPos = header.getBoundingClientRect().height;
	if(window.scrollY > headerPos && window.innerWidth > 1023){
		header.classList.add('header--sticky');
	} else if(window.scrollY > (window.innerHeight - headerPos) && window.innerWidth < 1023) {
		header.classList.add('header--sticky');
	} else {
		header.classList.remove('header--sticky');
	}
}


// inputs placeholders logic
function inputsLogic() {

	// placeholders move
	document.querySelectorAll('.form__input').forEach( input => {
		const next = input.nextElementSibling;
		if(!next.classList.contains('form__placeholder')) return;
		input.addEventListener('keyup', movePlaceholder.bind(input, next))
	})

	function movePlaceholder(next) {
		this.value.length !== 0 ? next.classList.add('is-changed') : next.classList.remove('is-changed');
	}

	// inputs type file
	const files = document.querySelectorAll('.form__input[type="file"]');
	files.forEach( input => input.addEventListener('change', function (e) {
		let file = this.files,
			name = '/' + file[0].name,
			textNode = this.parentNode.nextElementSibling;
		
		if(textNode.classList.contains('form__file-name')) {
			textNode.querySelectorAll('span')[0].textContent = name;
		}

		let closeElement = input.parentNode.nextElementSibling;
		console.log(closeElement)
		closeElement.querySelector('.js-file__close').addEventListener('click', function() {
			input.value = '';
			textNode.querySelectorAll('span')[0].textContent = 'no file'
		})

	}))
}
inputsLogic();


// forms sending functions
// forms submit logic
if(document.querySelector('form')){
	const forms = document.querySelectorAll('form'),
				regExp = [
					{
						type: 'tel',
						exp: '^\\+?3?8?0{1}[1-9]{2}\\d{7}$',
						flags: ''
					},
					{
						type: 'name',
						exp: '^[a-zA-z]{2,30}',
						flags: 'i'
					},
					{
						type: 'email',
						exp: '([\\w\\S]){1,}\\@{1}([\\w\\S]){1,}\\.{1}([a-z0-9]+){2,3}',
						flags: 'i'
					},
					{
						type: 'message',
						exp: '^\\S+?(\\D.){2,100}',
						flags: 'i'
					},
					{
						type: 'file',
						exp: '^\\S+?(\\D.){1,200}',
					}
				];
	

	forms.forEach( form => {
		
		let inputs = Array.from(form.querySelectorAll('input, textarea')),
				beforeSubmit = true,
				formData = new FormData();
		form.addEventListener( 'submit', submit);

		// unfocus from inputs
		inputs.forEach( input => {
			input.addEventListener('blur', function() {
				inputChecker(this, beforeSubmit);
			})
		})

		// function submit(beforeSubmit, formData, event){
		function submit(event){
			event.preventDefault();
			beforeSubmit = false;

			let validateInputs = inputs.map( input => {
				return inputChecker(input, beforeSubmit);
			})
			.every(element => element);

			// check for all valid fields
			if(!validateInputs) return;

			// collect all data from inputs
			inputs.forEach( input => {
				if(input.type !== 'checkbox' && input.type !== 'file') formData.append(input.name.toLowerCase(), input.value);
				if(input.type === 'file') {
					// let fileReader = new FileReader();
					let files = input.files[0];
					formData.append(input.name, files);

					// TODO:
					// fileReader.addEventListener('load', e => {
					// 	console.log(base)
					// 	formData.append(input.name, e.target.result);
					// })
					
				};
				if(input.type === 'checkbox') formData.append(input.name.toLowerCase(), input.checked);
			})
			
			requestApi(formData);
			
			// clear mass
			formData = new FormData();
		}
	})

	function inputChecker(element, beforeSubmit) {
		let reg = null,
				phoneRe = /\s*[\\(\\)]*/;
		
		if(element.type === 'checkbox') {
			printErrorText(element, element.checked, element.parentNode);
			return element.checked;
		}


		let res = regExp.some( obj => {
			if(obj[element.name.toLowerCase()]) {
				
			}

			if(obj.type.toLowerCase() !== element.name.toLowerCase()) return false;
			reg = new RegExp(obj.exp, obj.flags);
			
			if(beforeSubmit) return false;
			let checker = reg.test(element.value.split(phoneRe).join('')),
					parentNode = element.parentNode;

			printErrorText(element, checker, parentNode);
			return checker;
		})
		return res;
	}

	function printErrorText(element, checker, parentNode) {
		if(!checker && !parentNode.querySelector('span')){
			let errorSpan = document.createElement('span');
			element.classList.add('is_no-valid');
			errorSpan.classList.add('is_no-valid');
			errorSpan.textContent = element.dataset.textError;
			parentNode.appendChild(errorSpan);
		}
		
		if(checker && parentNode.querySelector('span')) {
			parentNode.querySelector('span').remove();
			element.classList.remove('is_no-valid');
		}
	}

	function requestApi(data) {
		fetch('/send' , {
			method: 'POST',
			body: data,
		}).then(response => {
			if(response.code === '200') {
				console.log('send');
				return;
			}
			console.log('declined');
			return;
		})
		.then(response => {
			// some logic in future XD
			// window.location.href = '/thanks';
		})
	}
}



// input mask for tel
if(document.querySelector('input[name="tel"]')){
	$('input[name="tel"]').inputmask("+38 (099) 99 99 999"); 
}



if(document.querySelector('.js-show__vacancyForm')) {
	$('.js-show__vacancyForm').click( function () {
		$('.js-vacancy__form').slideDown();
	})
}



// contacts page map init
// contacts function
if(document.querySelector('.map')){
	google.maps.event.addDomListener(window, 'load', init);
	
	let {lat, lng} = { lat: 50.4380734, lng: 30.4452469},
			maps = document.querySelectorAll('.map'),
			mapOptions = {
			zoom: 13,
			disableDefaultUI: true,
			center: new google.maps.LatLng(lat, lng),
			styles: [{"featureType":"water","elementType":"geometry","stylers":[{"color":"#e9e9e9"},{"lightness":17}]},{"featureType":"landscape","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":20}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#ffffff"},{"lightness":17}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#ffffff"},{"lightness":29},{"weight":0.2}]},{"featureType":"road.arterial","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":18}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":16}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":21}]},{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#dedede"},{"lightness":21}]},{"elementType":"labels.text.stroke","stylers":[{"visibility":"on"},{"color":"#ffffff"},{"lightness":16}]},{"elementType":"labels.text.fill","stylers":[{"saturation":36},{"color":"#333333"},{"lightness":40}]},{"elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"geometry","stylers":[{"color":"#f2f2f2"},{"lightness":19}]},{"featureType":"administrative","elementType":"geometry.fill","stylers":[{"color":"#fefefe"},{"lightness":20}]},{"featureType":"administrative","elementType":"geometry.stroke","stylers":[{"color":"#fefefe"},{"lightness":17},{"weight":1.2}]}]
	};
	
	function init() {
		maps.forEach( map => createMap(map));
	}

	function createMap(map) {
		let mapConstr = new google.maps.Map(map, mapOptions),
		marker = new google.maps.Marker({
			position: { 
				lat: Number(JSON.parse(window.map1.dataset.marker).lat), 
				lng: Number(JSON.parse(window.map1.dataset.marker).lng)
			},
			title: map.dataset.title,
			map: mapConstr,
			icon: map.dataset.mapImage
		});
	}
}
